var render = function render(){
  var _vm$document, _vm$document$paginati, _vm$selected$length, _vm$selected;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-table__wrapper"
  }, [_c('modal-costs-add', {
    on: {
      "costs_add": _vm.totalUpdateCosts
    }
  }), _c('modal-price-up', {
    on: {
      "price_up": _vm.totalUpdateMarkup
    }
  }), _c('modal-print-price-tags', {
    attrs: {
      "products": _vm.printProducts
    }
  }), _c('product-movement-modal', {
    attrs: {
      "product": _vm.getProduct
    }
  }), _c('marking-modal', {
    attrs: {
      "document_head": _vm.document.id,
      "spec": _vm.selectedProduct,
      "type": "outgoing"
    },
    on: {
      "update_fact": _vm.update_data
    }
  }), _c('div', {
    staticClass: "b-table__content mt-3",
    staticStyle: {
      "position": "relative"
    }
  }, [_c('outgoing-navbar', {
    staticStyle: {
      "position": "absolute",
      "bottom": "0",
      "right": "0",
      "left": "0"
    },
    attrs: {
      "items": _vm.isAllSelected ? (_vm$document = _vm.document) === null || _vm$document === void 0 ? void 0 : (_vm$document$paginati = _vm$document.paginationSpecifications) === null || _vm$document$paginati === void 0 ? void 0 : _vm$document$paginati.total : (_vm$selected$length = (_vm$selected = _vm.selected) === null || _vm$selected === void 0 ? void 0 : _vm$selected.length) !== null && _vm$selected$length !== void 0 ? _vm$selected$length : 0,
      "clear_selected": _vm.clearSelected
    },
    on: {
      "edit_product": _vm.edit_product,
      "remove_items": _vm.removeSpecs
    }
  }), _c('div', {
    staticClass: "table-filter"
  }, [_c('b-form-input', {
    staticClass: "filter-search",
    attrs: {
      "type": "text",
      "placeholder": "Поиск",
      "is-keyup": true
    },
    on: {
      "input": _vm.search
    }
  }), _c('b-button', {
    staticClass: "btn-more ml-3",
    on: {
      "click": _vm.openSortModal
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/settings.svg",
      "alt": ""
    }
  })])], 1), _c('div', {
    staticClass: "table-inner"
  }, [_c('resizable-table', {
    attrs: {
      "default_fields": _vm.default_fields,
      "table_name": "outgoing_fields",
      "inside_card": false,
      "show_product_search": "",
      "items": _vm.document.paginationSpecifications.specification
    },
    on: {
      "scroll-up": _vm.scrollUp,
      "scroll-down": _vm.scrollDown
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('b-form-checkbox', {
          staticClass: "cursor",
          model: {
            value: _vm.isAllSelected,
            callback: function ($$v) {
              _vm.isAllSelected = $$v;
            },
            expression: "isAllSelected"
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (data) {
        return [_c('b-form-checkbox', {
          staticClass: "cursor",
          attrs: {
            "value": data.item.id
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        })];
      }
    }, {
      key: "body_order",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.item.order) + " ")];
      }
    }, {
      key: "body_product",
      fn: function (data) {
        var _data$item, _data$item$product, _data$item2, _data$item2$product;

        return [_c('div', {
          staticClass: "text-left"
        }, [_vm._v(" " + _vm._s((_data$item = data.item) === null || _data$item === void 0 ? void 0 : (_data$item$product = _data$item.product) === null || _data$item$product === void 0 ? void 0 : _data$item$product.name) + " "), _c('div', {
          staticClass: "b-name article"
        }, [_vm._v(" " + _vm._s(((_data$item2 = data.item) === null || _data$item2 === void 0 ? void 0 : (_data$item2$product = _data$item2.product) === null || _data$item2$product === void 0 ? void 0 : _data$item2$product.articul) || 'Не указан') + " ")])])];
      }
    }, {
      key: "body_delta",
      fn: function (data) {
        var _data$item3, _data$item4, _data$item5, _data$item5$product$m, _data$item6, _data$item7, _data$item7$product$m;

        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "row",
            "align-self": "center",
            "justify-content": "center"
          }
        }, [_c('table-row-input', {
          ref: ((_data$item3 = data.item) === null || _data$item3 === void 0 ? void 0 : _data$item3.id) + 'delta',
          attrs: {
            "value": (_data$item4 = data.item) === null || _data$item4 === void 0 ? void 0 : _data$item4.delta,
            "fixed": _vm.document.fixed || ((_data$item5 = data.item) === null || _data$item5 === void 0 ? void 0 : (_data$item5$product$m = _data$item5.product.markingType) === null || _data$item5$product$m === void 0 ? void 0 : _data$item5$product$m.code) === 'ALCO',
            "input_id": ((_data$item6 = data.item) === null || _data$item6 === void 0 ? void 0 : _data$item6.id) + 'delta',
            "digits": 3
          },
          on: {
            "handle-input": function (val) {
              return _vm.setDelta(val, data.item);
            },
            "input": function (val) {
              return _vm.setDelta(val, data.item);
            },
            "on-focus": _vm.centeredScroll
          }
        }), ((_data$item7 = data.item) === null || _data$item7 === void 0 ? void 0 : (_data$item7$product$m = _data$item7.product.markingType) === null || _data$item7$product$m === void 0 ? void 0 : _data$item7$product$m.code) === 'ALCO' ? [_c('img', {
          staticClass: "ml-3 cursor",
          attrs: {
            "src": "/img/icons/docs/marking.svg",
            "alt": ""
          },
          on: {
            "click": function ($event) {
              return _vm.showMarkingModal(data.item);
            }
          }
        })] : _vm._e()], 2)];
      }
    }, {
      key: "body_measurement",
      fn: function (data) {
        var _data$item8, _data$item8$product$m;

        return [_vm._v(" " + _vm._s(((_data$item8 = data.item) === null || _data$item8 === void 0 ? void 0 : (_data$item8$product$m = _data$item8.product.measurement) === null || _data$item8$product$m === void 0 ? void 0 : _data$item8$product$m.name) || '–') + " ")];
      }
    }, {
      key: "body_price",
      fn: function (data) {
        var _data$item9;

        return [_c('table-row-input', {
          attrs: {
            "value": (_data$item9 = data.item) === null || _data$item9 === void 0 ? void 0 : _data$item9.price,
            "fixed": _vm.document.fixed,
            "symbol": "₽"
          },
          on: {
            "handle-input": function (val) {
              return _vm.setPrice(val, data.item);
            },
            "input": function (val) {
              return _vm.setPrice(val, data.item);
            },
            "on-focus": _vm.centeredScroll
          }
        })];
      }
    }, {
      key: "body_sum",
      fn: function (data) {
        var _data$item10;

        return [_c('table-row-input', {
          attrs: {
            "value": (_data$item10 = data.item) === null || _data$item10 === void 0 ? void 0 : _data$item10.sum,
            "fixed": _vm.document.fixed,
            "symbol": "₽"
          },
          on: {
            "handle-input": function (val) {
              return _vm.setSum(val, data.item);
            },
            "input": function (val) {
              return _vm.setSum(val, data.item);
            },
            "on-focus": _vm.centeredScroll
          }
        })];
      }
    }, {
      key: "body_markup",
      fn: function (data) {
        var _data$item11;

        return [_c('table-row-input', {
          attrs: {
            "value": (_data$item11 = data.item) === null || _data$item11 === void 0 ? void 0 : _data$item11.markup,
            "fixed": _vm.document.fixed,
            "symbol": "%"
          },
          on: {
            "handle-input": function (val) {
              return _vm.setMarkup(val, data.item);
            },
            "input": function (val) {
              return _vm.setMarkup(val, data.item);
            },
            "on-focus": _vm.centeredScroll
          }
        })];
      }
    }, {
      key: "body_retailPrice",
      fn: function (data) {
        var _data$item12;

        return [_c('table-row-input', {
          attrs: {
            "value": (_data$item12 = data.item) === null || _data$item12 === void 0 ? void 0 : _data$item12.retailPrice,
            "fixed": _vm.document.fixed,
            "symbol": "₽"
          },
          on: {
            "handle-input": function (val) {
              return _vm.setRetailPrice(val, data.item);
            },
            "input": function (val) {
              return _vm.setRetailPrice(val, data.item);
            },
            "on-focus": _vm.centeredScroll
          }
        })];
      }
    }, {
      key: "body_currentPrice",
      fn: function (data) {
        var _data$item$product$re, _data$item13, _data$item13$product;

        return [_vm._v(" " + _vm._s(_vm.formatPrice((_data$item$product$re = (_data$item13 = data.item) === null || _data$item13 === void 0 ? void 0 : (_data$item13$product = _data$item13.product) === null || _data$item13$product === void 0 ? void 0 : _data$item13$product.retailPrice) !== null && _data$item$product$re !== void 0 ? _data$item$product$re : 0)) + " ₽ ")];
      }
    }, {
      key: "body_nds",
      fn: function (data) {
        var _data$item14, _data$item14$nds, _data$item15;

        return [_c('table-select-input', {
          attrs: {
            "option_list": _vm.taxRateList,
            "value": (_data$item14 = data.item) === null || _data$item14 === void 0 ? void 0 : (_data$item14$nds = _data$item14.nds) === null || _data$item14$nds === void 0 ? void 0 : _data$item14$nds.id,
            "fixed": _vm.document.fixed,
            "symbol": "₽",
            "input_id": ((_data$item15 = data.item) === null || _data$item15 === void 0 ? void 0 : _data$item15.id) + 'nds'
          },
          on: {
            "handle-input": function (val) {
              return _vm.setNds(val, data.item);
            },
            "on-focus": _vm.centeredScroll
          }
        })];
      }
    }, {
      key: "body_taxRateSum",
      fn: function (data) {
        var _data$item16, _data$item16$taxRateS;

        return [_vm._v(" " + _vm._s(+((_data$item16 = data.item) === null || _data$item16 === void 0 ? void 0 : (_data$item16$taxRateS = _data$item16.taxRateSum) === null || _data$item16$taxRateS === void 0 ? void 0 : _data$item16$taxRateS.toFixed(2)) || 0) + " ₽ ")];
      }
    }, {
      key: "body_remainders",
      fn: function (data) {
        var _data$item17, _data$item17$product;

        return [_vm._v(" " + _vm._s(_vm.getRemainder((_data$item17 = data.item) === null || _data$item17 === void 0 ? void 0 : (_data$item17$product = _data$item17.product) === null || _data$item17$product === void 0 ? void 0 : _data$item17$product.remainders)) + " ")];
      }
    }, {
      key: "body_costs",
      fn: function (data) {
        var _data$item18, _data$item18$cost;

        return [_vm._v(" " + _vm._s(+((_data$item18 = data.item) === null || _data$item18 === void 0 ? void 0 : (_data$item18$cost = _data$item18.cost) === null || _data$item18$cost === void 0 ? void 0 : _data$item18$cost.toFixed(2)) || 0) + " ")];
      }
    }, {
      key: "productSearch",
      fn: function () {
        var _vm$document$supplier, _vm$document2, _vm$document2$supplie;

        return [_c('div', {
          staticClass: "d-flex",
          staticStyle: {
            "width": "200px"
          }
        }, [_c('product-search', {
          staticClass: "ml-2",
          staticStyle: {
            "width": "250px"
          },
          attrs: {
            "supplier": (_vm$document$supplier = (_vm$document2 = _vm.document) === null || _vm$document2 === void 0 ? void 0 : (_vm$document2$supplie = _vm$document2.supplier) === null || _vm$document2$supplie === void 0 ? void 0 : _vm$document2$supplie.id) !== null && _vm$document$supplier !== void 0 ? _vm$document$supplier : null,
            "document_head": _vm.document.id,
            "document": _vm.document,
            "document_type": 'outgoing'
          },
          on: {
            "select_product": _vm.select_product
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "total",
      fn: function () {
        var _vm$document$paginati2, _vm$document$paginati3, _vm$document$paginati4, _vm$document$paginati5, _vm$document$paginati6;

        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('div', {
          staticClass: "footer-data"
        }, [_vm._v(" Итого: " + _vm._s((_vm$document$paginati2 = _vm.document.paginationSpecifications) === null || _vm$document$paginati2 === void 0 ? void 0 : _vm$document$paginati2.total) + " ")]), _c('div', {
          staticClass: "footer-data"
        }, [_vm._v(" Количество: "), _c('span', [_vm._v(" " + _vm._s(+((_vm$document$paginati3 = _vm.document.paginationSpecifications) === null || _vm$document$paginati3 === void 0 ? void 0 : (_vm$document$paginati4 = _vm$document$paginati3.factTotal) === null || _vm$document$paginati4 === void 0 ? void 0 : _vm$document$paginati4.toFixed(2)) || 0) + " ")])]), _c('div', {
          staticClass: "footer-data"
        }, [_vm._v(" Сумма: "), _c('span', [_vm._v(" " + _vm._s(_vm.formatPrice((_vm$document$paginati5 = _vm.document.paginationSpecifications) === null || _vm$document$paginati5 === void 0 ? void 0 : _vm$document$paginati5.sumTotal)) + " ₽ ")])]), _c('div', {
          staticClass: "footer-data"
        }, [_vm._v(" Сумма НДС: " + _vm._s(_vm.formatPrice((_vm$document$paginati6 = _vm.document.paginationSpecifications) === null || _vm$document$paginati6 === void 0 ? void 0 : _vm$document$paginati6.taxRateSum)) + " ₽ ")])])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }