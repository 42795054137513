<template>
  <div class="params-body">
    <div class="card pb-0 pr-0 mb-0">
      <b-form class="form">
        <b-row class="w-100 align-items-end">
          <b-col
            lg="3"
            md="6"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Дата"
              label-for="work_period"
            >
              <template v-if="fixed">
                {{ convertDate(filter_params.date) }}
              </template>
              <template v-else>
                <date-picker
                  id="work_period"
                  class="elm-calendar"
                  :clearable="false"
                  placeholder="Выберите дату"
                  :value="filter_params.date"
                  format="dd.MM.yyyy"
                  :is-keyup="true"
                  transfer
                  @on-change="setDate"
                />
              </template>
            </b-form-group>
          </b-col>
          <b-col
            lg="3"
            md="6"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Поставщик или юрлицо"
              label-for="supplier"
              :class="{ error: error_supplier }"
            >
              <template v-if="fixed">
                {{ filter_params.supplier.name }}
              </template>
              <template v-else>
                <i-select
                  :value="filter_params.supplier.id"
                  filterable
                  :disabled="fixed"
                  :is-keyup="true"
                  transfer
                  @on-change="setSupplier"
                >
                  <i-option
                    v-for="item in supplier_list"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </i-option>
                </i-select>
              </template>
            </b-form-group>
          </b-col>
          <b-col
            lg="3"
            md="6"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Операция"
              label-for="operation"
            >
              <i-select
                id="operation"
                :value="filter_params.operation.name"
                :disabled="fixed"
                transfer
                @on-change="setOperation"
              >
                <i-option
                  v-for="item in operation_list"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </i-option>
              </i-select>
            </b-form-group>
          </b-col>
          <b-col
            lg="3"
            md="6"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Место хранения"
              label-for="storage"
              :class="{ error: error_storage }"
            >
              <template v-if="fixed">
                {{ filter_params.storage.name }}
              </template>
              <template v-else>
                <i-select
                  id="storage"
                  :value="filter_params.storage.id"
                  transfer
                  @on-change="changeStorage"
                >
                  <i-option
                    v-for="item in storages_list"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </i-option>
                </i-select>
              </template>
            </b-form-group>
          </b-col>
          <b-col
            lg="3"
            md="6"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Организация"
              label-for="entity"
              :class="{ error: this.error_entity }"
            >
              <template v-if="fixed">
                {{ filter_params.entity.name }}
              </template>
              <e-select
                v-else
                v-model="active_entity"
                selected_type="text"
                :error="this.error_entity"
                :options="entity_list"
                select_name="entity_select"
              >
                <template #custom_name="{ item }">
                  <div class="entity">
                    <p class="name">{{ item.name }}</p>
                    <div class="inn_kpp">
                      <p class="inn">ИНН: {{ item.inn ? item.inn : '-' }}</p>
                      <p class="kpp">КПП: {{ item.kpp ? item.kpp : '-' }}</p>
                    </div>
                  </div>
                </template>
              </e-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="w-100">
          <b-col>
            <div
              v-b-toggle.collapse-extraparams
              class="btn-extra-param cursor mb-3"
            >
              <span class="isHide">Показать</span>
              <span class="isShow">Скрыть</span>
              дополнительные параметры
            </div>
            <b-collapse
              id="collapse-extraparams"
              class="row mb-3"
            >
              <b-col
                lg="6"
                md="12"
                class="pr-0"
              >
                <b-form-group
                  label="Комментарий"
                  label-for="comment"
                >
                  <b-form-textarea
                    id="comment"
                    :value="filter_params.comment"
                    rows="3"
                    max-rows="6"
                    :is-keyup="true"
                    @input="setComment"
                  />
                </b-form-group>
              </b-col>
            </b-collapse>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  import { mapGetters } from 'vuex'
  import { OutgoingHeadModel } from '@/views/operational-processes/models/outgoing-head.model'

  export default {
    props: {
      error_storage: Boolean,
      error_supplier: Boolean,
      error_entity: Boolean,
      menu_list: {
        type: Array,
        default: () => []
      },

      storages_list: {
        type: Array,
        default: () => []
      },

      filter_params: {
        type: OutgoingHeadModel,
        default: null
      },

      fixed: {
        type: Boolean,
        default: false
      },

      is_supplier_error: {
        type: Boolean,
        default: false
      }
    },

    apollo: {
      Suppliers: {
        query: require('../../gql/getSuppliers.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.supplier_list = data?.Suppliers ?? []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data: function () {
      return {
        active_entity: [],
        entity_list: [],
        operation_list: [
          {
            name: 'Возврат',
            id: 'return'
          },
          {
            name: 'Списание',
            id: 'write_off'
          }
        ],
        supplier_list: [],
        isUpdatedFilterParams: false
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    },

    watch: {
      filter_params() {
        if (this.filter_params?.entity?.id) {
          this.active_entity = [this.filter_params.entity]
        }
      },
      active_entity(newVal) {
        this.$emit('set_error_entity')
        this.filter_params.setEntity(newVal[0])
        this.$emit('change')
      }
    },

    mounted() {
      this.entity_list = this.currentBranch?.entities
      if (this.filter_params?.entity) {
        this.active_entity = [this.currentBranch?.entities.find((obj) => obj.id === this.filter_params?.entity)]
      }
    },

    methods: {
      changeStorage(id) {
        this.$emit('set_error_storage')
        this.storages_list.filter((item) => {
          if (item.id.includes(id)) this.filter_params.setStorage(item)
        })

        this.$nextTick(() => this.$emit('change'))
      },
      setOperation(val) {
        this.filter_params.setOperation(val)
        this.$nextTick(() => this.$emit('change'))
      },
      setSupplier(val) {
        this.$emit('set_error_supplier')
        const supplier = this.supplier_list.find((el) => el.id === val)
        if (this.currentBranch.entities?.some((el) => el.inn === supplier.inn)) {
          this.setOperation('write_off')
        } else this.setOperation('return')
        this.filter_params.setSupplier(supplier)
        this.$emit('change')
      },
      setComment(val) {
        this.filter_params.setComment(val)
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.$emit('change')
        }, 500)
      },
      setDate(val) {
        this.filter_params.setDate(this.toNormalDate(val))
        this.$emit('change')
      },
      toNormalDate(val) {
        const parts = val.split('.')
        return new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10))
      },
      convertDate(date) {
        if (date) return formatDate(new Date(date))
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .params-body {
      padding-top: 16px;
      .form {
        .col-lg-3 {
          margin-bottom: 16px;
        }
      }

      .btn-extra-param {
        border-bottom: 1px solid #313131;
        display: inline-block;

        .isShow {
          display: none;
        }

        &.not-collapsed {
          .isHide {
            display: none;
          }

          .isShow {
            display: inline;
          }
        }
      }

      textarea {
        overflow: hidden;
        resize: none;
      }
    }
  }

  .ms__dropdown-item.active {
    .name {
      color: #00a3ff;
    }
  }
  .entity {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    p {
      margin-bottom: 0;
    }
    .name {
      display: flex;
      float: left;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #313131;
    }
    .inn_kpp {
      display: flex;
      .inn {
        margin-right: 5px;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #888888;
      }
    }
  }
  .params-body {
    padding-top: 16px;
    .form {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      gap: 0;

      ::v-deep .form-group {
        min-width: 150px;
        margin-bottom: 0;
      }

      .col-lg-3 {
        margin-bottom: 16px;
      }
    }

    .btn-extra-param {
      border-bottom: 1px solid #313131;
      display: inline-block;

      .isShow {
        display: none;
      }

      &.not-collapsed {
        .isHide {
          display: none;
        }

        .isShow {
          display: inline;
        }
      }
    }
  }
</style>
