var render = function render(){
  var _vm$spec2, _vm$spec3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "marking-modal",
      "centered": "",
      "size": "lg",
      "hide-footer": "",
      "header-class": "m-0"
    },
    on: {
      "show": _vm.refetchMarking
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        var _vm$spec, _vm$spec$product;

        return [_c('h5', [_vm._v("Список кодов маркировки | " + _vm._s(((_vm$spec = _vm.spec) === null || _vm$spec === void 0 ? void 0 : (_vm$spec$product = _vm$spec.product) === null || _vm$spec$product === void 0 ? void 0 : _vm$spec$product.name) || 'NONAME SSSSS'))]), _c('b-button', {
          staticClass: "close",
          on: {
            "click": _vm.hideModal
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('span', {
    staticClass: "mr-3"
  }, [_vm._v("Заявлено: " + _vm._s(((_vm$spec2 = _vm.spec) === null || _vm$spec2 === void 0 ? void 0 : _vm$spec2.requestDelta) || 0))]), _c('span', [_vm._v("Факт: " + _vm._s(((_vm$spec3 = _vm.spec) === null || _vm$spec3 === void 0 ? void 0 : _vm$spec3.delta) || 0))]), _c('b-form-input', {
    staticClass: "mt-4 mb-3",
    staticStyle: {
      "width": "35%"
    },
    attrs: {
      "placeholder": "Поиск"
    },
    model: {
      value: _vm.pagination.search,
      callback: function ($$v) {
        _vm.$set(_vm.pagination, "search", $$v);
      },
      expression: "pagination.search"
    }
  }), _c('div', {
    staticStyle: {
      "border-right": "1px solid #e1e1e1",
      "border-left": "1px solid #e1e1e1"
    }
  }, [_c('b-table', {
    attrs: {
      "fields": _vm.fields,
      "items": _vm.marks,
      "show-empty": "",
      "empty-text": "Нет маркировки"
    },
    scopedSlots: _vm._u([{
      key: "emptyfiltered",
      fn: function () {
        return [_c('span', {
          staticClass: "center"
        }, [_vm._v(" Нет маркировки ")])];
      },
      proxy: true
    }, {
      key: "head(fact)",
      fn: function () {
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "row",
            "align-items": "center"
          }
        }, [_c('b-checkbox', {
          attrs: {
            "switch": "",
            "disabled": ""
          }
        }), _c('span', [_vm._v("Статус кода маркировки")])], 1)];
      },
      proxy: true
    }, {
      key: "cell(fact)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "row",
            "align-items": "center"
          }
        }, [_c('b-checkbox', {
          attrs: {
            "switch": ""
          },
          on: {
            "change": function (val) {
              return _vm.setFact(val, item);
            }
          },
          model: {
            value: item.fact,
            callback: function ($$v) {
              _vm.$set(item, "fact", $$v);
            },
            expression: "item.fact"
          }
        }), _c('span', {
          staticClass: "ml-2",
          staticStyle: {
            "padding": "5px 12px",
            "font-size": "14px"
          },
          style: {
            background: _vm.colors[item.fact ? 'accepted' : 'not_accepted'].background,
            color: _vm.colors[item.fact ? 'accepted' : 'not_accepted'].color
          }
        }, [_vm._v(_vm._s(item.fact ? 'Принята' : 'Не принята'))])], 1)];
      }
    }, {
      key: "cell(serial_number)",
      fn: function (_ref2) {
        var _item$serial$serial_n, _item$serial;

        var item = _ref2.item;
        return [_c('div', {
          staticClass: "mark-code"
        }, [_c('div', {
          staticClass: "serial_number"
        }, [_vm._v(" " + _vm._s((_item$serial$serial_n = (_item$serial = item.serial) === null || _item$serial === void 0 ? void 0 : _item$serial.serial_number) !== null && _item$serial$serial_n !== void 0 ? _item$serial$serial_n : '-') + " ")]), _c('div', {
          on: {
            "click": function ($event) {
              var _item$serial2;

              return _vm.copy((_item$serial2 = item.serial) === null || _item$serial2 === void 0 ? void 0 : _item$serial2.serial_number);
            }
          }
        }, [_c('svg', {
          attrs: {
            "width": "16",
            "height": "18",
            "viewBox": "0 0 16 18",
            "fill": "none",
            "xmlns": "http://www.w3.org/2000/svg"
          }
        }, [_c('path', {
          attrs: {
            "d": "M9.93359 18H3.21875C1.66789 18 0.40625 16.7384 0.40625 15.1875V5.66016C0.40625 4.1093 1.66789 2.84766 3.21875 2.84766H9.93359C11.4845 2.84766 12.7461 4.1093 12.7461 5.66016V15.1875C12.7461 16.7384 11.4845 18 9.93359 18ZM3.21875 4.25391C2.44339 4.25391 1.8125 4.8848 1.8125 5.66016V15.1875C1.8125 15.9629 2.44339 16.5938 3.21875 16.5938H9.93359C10.709 16.5938 11.3398 15.9629 11.3398 15.1875V5.66016C11.3398 4.8848 10.709 4.25391 9.93359 4.25391H3.21875ZM15.5586 13.4297V2.8125C15.5586 1.26164 14.297 0 12.7461 0H4.94141C4.55304 0 4.23828 0.314758 4.23828 0.703125C4.23828 1.09149 4.55304 1.40625 4.94141 1.40625H12.7461C13.5215 1.40625 14.1523 2.03714 14.1523 2.8125V13.4297C14.1523 13.8181 14.4671 14.1328 14.8555 14.1328C15.2438 14.1328 15.5586 13.8181 15.5586 13.4297Z",
            "fill": "#313131"
          }
        })])])])];
      }
    }])
  })], 1), _vm.type === 'outgoing' ? _c('pagination', {
    attrs: {
      "total": _vm.total
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }