<template>
  <div>
    <div class="b-table__filter d-flex justify-content-between">
      <div class="b-toggle-button">
        <b-button
          class="btn-filters cursor"
          @click="changeVisibleParams"
        >
          Параметры документа
          <img
            :class="{ rotate: visibleParams }"
            class="ml-3"
            src="/img/icons/arrow_up.svg"
            alt=""
          />
        </b-button>
      </div>
      <div class="b-action-buttons d-flex">
        <e-button
          v-if="!document.fixed"
          size="m"
          variant="outline-primary"
          class="cursor"
          @click="back"
        >
          Сохранить черновик
        </e-button>
        <e-button
          size="m"
          variant="primary"
          class="cursor ml-3"
          :loading="loading"
          :disabled="loading"
          @click="updateDocument(document.fixed)"
        >
          <template v-if="is_request_update">
            <b-spinner
              variant="light"
              small
            />
          </template>
          <template v-else>
            {{ document.fixed ? 'Редактировать' : 'Провести' }}
          </template>
        </e-button>
      </div>
    </div>
    <e-collapse
      id="collapse-filters"
      v-model="visibleParams"
      :show-button="false"
    >
      <template #content>
        <filters-outcoming
          ref="header_filters_outgoing"
          :fixed="document?.fixed"
          :filter_params="document"
          :is_supplier_error="isSupplierError"
          :error_storage="errorStorage"
          :error_supplier="errorSupplier"
          :error_entity="errorEntity"
          :storages_list="storages_list"
          @set_error_storage="errorStorage = null"
          @set_error_entity="errorEntity = null"
          @set_error_supplier="errorSupplier = null"
          @change="change"
        />
      </template>
    </e-collapse>
  </div>
</template>

<script>
  import FiltersOutcoming from '@/views/operational-processes/components/overhead/FiltersOutcoming'
  import { OutgoingHeadModel } from '@/views/operational-processes/models/outgoing-head.model'

  export default {
    name: 'OutgoingHead',
    components: {
      FiltersOutcoming
    },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      storages_list: {
        type: Array,
        default: () => []
      },
      document: {
        type: OutgoingHeadModel
      }
    },
    data() {
      return {
        visibleParams: false,
        isSupplierError: false,
        is_request_update: false,
        errorStorage: null,
        errorSupplier: null,
        errorEntity: null
      }
    },
    destroyed() {
      localStorage.setItem('newDocHeader', 'false')
    },
    beforeMount() {
      this.visibleParams =
        localStorage.getItem('newDocHeader') === 'true' || localStorage.getItem('docHeader') === 'true'
    },
    methods: {
      changeVisibleParams() {
        this.visibleParams = !this.visibleParams
        if (this.visibleParams) {
          localStorage.setItem('docHeader', 'true')
        } else {
          localStorage.setItem('docHeader', 'false')
        }
      },
      updateDocument(status) {
        if (!this.document.supplier?.id && !this.document.storage?.id && !this.document.entity?.id) {
          this.errorStorage = true
          this.errorSupplier = true
          this.errorEntity = true
          this.$noty.error('Необходимо выбрать поставщика, место хранения и организацию')
          return
        }
        if (!this.document.supplier?.id) {
          this.errorSupplier = true
          this.$noty.error('Выберите поставщика')
          return
        }
        if (!this.document.storage?.id) {
          this.errorStorage = true
          this.$noty.error('Выберите место хранения')
          return
        }
        if (!this.document.entity?.id) {
          this.errorEntity = true
          this.$noty.error('Выберите организацию')
          return
        }
        if (!this.document.paginationSpecifications?.specification?.length) {
          this.$noty.error('Документ должен содержать спецификацию')
          return
        }
        this.$emit('makeUsed', !status)
      },
      makeFixed(status) {
        if (!this.document.supplier?.id) {
          this.isSupplierError = true
          this.$noty.show('Необходимо выбрать поставщика')
        } else {
          this.isSupplierError = false
          this.$emit('makeUsed', !status)
        }
      },
      back() {
        this.$emit('onChange')
        this.$router.back()
      },
      change() {
        this.$emit('onChange')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .b-table__filter {
    .b-toggle-button {
      button {
        background: #e2e2e2;
        color: #313131;

        img {
          margin-right: 0;
          transition: all 0.2s;
        }

        &.not-collapsed {
          img {
            transform: rotate(0);
          }
        }
      }
    }
  }

  .table-filter {
    button {
      background: #efefef;
      color: #313131;

      &:hover {
        background: #efefef;
        color: #313131;
      }
    }
  }
  ::v-deep .elm-calendar input {
    height: 31px;
  }
  .rotate {
    transform: rotate(360deg) !important;
  }
</style>
