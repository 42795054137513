<template>
  <div class="b-table__wrapper">
    <modal-costs-add @costs_add="totalUpdateCosts" />
    <modal-price-up @price_up="totalUpdateMarkup" />
    <modal-print-price-tags :products="printProducts" />
    <product-movement-modal :product="getProduct" />
    <marking-modal
      :document_head="document.id"
      :spec="selectedProduct"
      type="outgoing"
      @update_fact="update_data"
    />

    <div
      class="b-table__content mt-3"
      style="position: relative"
    >
      <outgoing-navbar
        style="position: absolute; bottom: 0; right: 0; left: 0"
        :items="isAllSelected ? document?.paginationSpecifications?.total : selected?.length ?? 0"
        :clear_selected="clearSelected"
        @edit_product="edit_product"
        @remove_items="removeSpecs"
      />
      <div class="table-filter">
        <b-form-input
          type="text"
          class="filter-search"
          placeholder="Поиск"
          :is-keyup="true"
          @input="search"
        />
        <b-button
          class="btn-more ml-3"
          @click="openSortModal"
        >
          <img
            src="/img/icons/settings.svg"
            alt=""
          />
        </b-button>
      </div>
      <div class="table-inner">
        <resizable-table
          :default_fields="default_fields"
          table_name="outgoing_fields"
          :inside_card="false"
          show_product_search
          :items="document.paginationSpecifications.specification"
          @scroll-up="scrollUp"
          @scroll-down="scrollDown"
        >
          <template #head_id>
            <b-form-checkbox
              v-model="isAllSelected"
              class="cursor"
            />
          </template>
          <template #body_id="data">
            <b-form-checkbox
              v-model="selected"
              class="cursor"
              :value="data.item.id"
            />
          </template>

          <template #body_order="data">
            {{ data.item.order }}
          </template>

          <template #body_product="data">
            <div class="text-left">
              {{ data.item?.product?.name }}
              <div class="b-name article">
                {{ data.item?.product?.articul || 'Не указан' }}
              </div>
            </div>
          </template>

          <template #body_delta="data">
            <div style="display: flex; flex-direction: row; align-self: center; justify-content: center">
              <table-row-input
                :ref="data.item?.id + 'delta'"
                :value="data.item?.delta"
                :fixed="document.fixed || data.item?.product.markingType?.code === 'ALCO'"
                :input_id="data.item?.id + 'delta'"
                :digits="3"
                @handle-input="(val) => setDelta(val, data.item)"
                @input="(val) => setDelta(val, data.item)"
                @on-focus="centeredScroll"
              />
              <template v-if="data.item?.product.markingType?.code === 'ALCO'">
                <img
                  src="/img/icons/docs/marking.svg"
                  alt=""
                  class="ml-3 cursor"
                  @click="showMarkingModal(data.item)"
                />
              </template>
            </div>
          </template>

          <template #body_measurement="data">
            {{ data.item?.product.measurement?.name || '–' }}
          </template>

          <template #body_price="data">
            <table-row-input
              :value="data.item?.price"
              :fixed="document.fixed"
              symbol="₽"
              @handle-input="(val) => setPrice(val, data.item)"
              @input="(val) => setPrice(val, data.item)"
              @on-focus="centeredScroll"
            />
          </template>

          <template #body_sum="data">
            <table-row-input
              :value="data.item?.sum"
              :fixed="document.fixed"
              symbol="₽"
              @handle-input="(val) => setSum(val, data.item)"
              @input="(val) => setSum(val, data.item)"
              @on-focus="centeredScroll"
            />
          </template>

          <template #body_markup="data">
            <table-row-input
              :value="data.item?.markup"
              :fixed="document.fixed"
              symbol="%"
              @handle-input="(val) => setMarkup(val, data.item)"
              @input="(val) => setMarkup(val, data.item)"
              @on-focus="centeredScroll"
            />
          </template>

          <template #body_retailPrice="data">
            <table-row-input
              :value="data.item?.retailPrice"
              :fixed="document.fixed"
              symbol="₽"
              @handle-input="(val) => setRetailPrice(val, data.item)"
              @input="(val) => setRetailPrice(val, data.item)"
              @on-focus="centeredScroll"
            />
          </template>

          <template #body_currentPrice="data"> {{ formatPrice(data.item?.product?.retailPrice ?? 0) }} ₽ </template>

          <template #body_nds="data">
            <table-select-input
              :option_list="taxRateList"
              :value="data.item?.nds?.id"
              :fixed="document.fixed"
              symbol="₽"
              :input_id="data.item?.id + 'nds'"
              @handle-input="(val) => setNds(val, data.item)"
              @on-focus="centeredScroll"
            />
          </template>

          <template #body_taxRateSum="data"> {{ +data.item?.taxRateSum?.toFixed(2) || 0 }} ₽ </template>

          <template #body_remainders="data">
            {{ getRemainder(data.item?.product?.remainders) }}
          </template>

          <template #body_costs="data">
            {{ +data.item?.cost?.toFixed(2) || 0 }}
          </template>
          <template #productSearch>
            <div
              class="d-flex"
              style="width: 200px"
            >
              <product-search
                :supplier="document?.supplier?.id ?? null"
                style="width: 250px"
                class="ml-2"
                :document_head="document.id"
                :document="document"
                :document_type="'outgoing'"
                @select_product="select_product"
              />
            </div>
          </template>
          <template #total>
            <div class="d-flex">
              <div class="footer-data">
                Итого:
                {{ document.paginationSpecifications?.total }}
              </div>

              <div class="footer-data">
                Количество:
                <span>
                  {{ +document.paginationSpecifications?.factTotal?.toFixed(2) || 0 }}
                </span>
              </div>

              <div class="footer-data">
                Сумма:
                <span>
                  {{ formatPrice(document.paginationSpecifications?.sumTotal) }}
                  ₽
                </span>
              </div>
              <div class="footer-data">
                Сумма НДС:
                {{ formatPrice(document.paginationSpecifications?.taxRateSum) }}
                ₽
              </div>
            </div>
          </template>
        </resizable-table>
      </div>
    </div>
  </div>
</template>

<script>
  import ModalPrintPriceTags from '@/views/core/modal/ModalPrintPriceTags'
  import ModalPriceUp from '@/views/operational-processes/components/overhead/ModalPriceUp'
  import { mapGetters } from 'vuex'
  import ModalCostsAdd from '@/views/operational-processes/components/overhead/ModalCostsAdd'
  import TableRowInput from '@/views/operational-processes/components/TableRowInput'
  import { TaxRateModel } from '@/models/tax-rate.model'
  import SortTableModal from '@/views/operational-processes/components/SortTableModal'
  import ProductSearch from '@/views/operational-processes/components/ProductSearch'
  import { ProductModel } from '@/models/product.model'
  import { OutgoingHeadModel } from '@/views/operational-processes/models/outgoing-head.model'
  import { OutgoingSpecificationModel } from '@/views/operational-processes/models/outgoing-spec.model'
  import OutgoingNavbar from '@/views/operational-processes/components/outgoing/OutgoingNavbar'
  import ProductMovementModal from '@/views/products/modal/ProductMovementModal.vue'
  import TableSelectInput from '../TableSelectInput.vue'
  import ResizableTable from '@/components/ResizableTable'
  import MarkingModal from '@/views/operational-processes/components/incoming/MarkingModal.vue'
  import { EventBus } from '@/main'

  export default {
    components: {
      MarkingModal,
      ProductMovementModal,
      OutgoingNavbar,
      ProductSearch,
      SortTableModal,
      TableRowInput,
      ModalPrintPriceTags,
      ModalPriceUp,
      ModalCostsAdd,
      TableSelectInput,
      ResizableTable
    },
    props: {
      document: {
        type: OutgoingHeadModel
      },
      busy: {
        type: Boolean
      }
    },
    data() {
      return {
        isUpdateFilters: false,
        searchString: '',
        search_result: false,
        fields: [],
        printProducts: [],
        specToCreate: new OutgoingSpecificationModel(),
        selectedProduct: null,
        handler_product: false,
        default_fields: [
          {
            key: 'id',
            label: '',
            isRowHeader: true,
            class: 'center b-checkbox',
            thClass: 'white-bg',
            checked: true,
            width: 45
          },
          {
            key: 'order',
            label: '№',
            class: 'center',
            checked: true,
            width: 54,
            thClass: 'white-bg'
          },
          {
            key: 'product',
            label: 'Наименование товара',
            checked: true,
            class: 'text-left td-name',
            width: 250,
            thClass: 'white-bg'
          },
          {
            key: 'delta',
            label: 'Общее количество',
            class: 'center',
            checked: true,
            width: 100,
            thClass: 'white-bg'
          },
          {
            key: 'measurement',
            label: 'Ед. изм.',
            class: 'center',
            checked: true,
            width: 54,
            formatter: (value, key, item) => item.product?.measurement?.name ?? '-'
          },
          {
            key: 'price',
            label: 'Закупочная цена',
            class: 'center',
            checked: true,
            width: 150,
            thClass: 'white-bg'
          },
          {
            key: 'sum',
            label: 'Сумма по позиции',
            class: 'center',
            checked: true,
            width: 150
          },
          {
            key: 'currentPrice',
            label: 'Розница текущая',
            class: 'center',
            checked: true,
            width: 120
          },
          {
            key: 'nds',
            label: 'Ставка НДС',
            class: 'center',
            checked: true,
            width: 120
          },
          {
            key: 'taxRateSum',
            label: 'Сумма НДС (₽)',
            class: 'center',
            checked: true,
            width: 120
          },
          {
            key: 'remainders',
            label: 'Остаток',
            class: 'center',
            checked: true,
            width: 100,
            formatter: (value, key, item) => this.getRemainder(item.product.remainders)
          }
        ],
        selected: [],
        isAllSelected: false,
        taxRateList: [],
        thElm: 0,
        startOffset: 0,
        field: null,
        scrollExist: true,
        isModalOpened: false
      }
    },

    apollo: {
      TaxRates: {
        query: require('../../gql/getTaxRates.gql'),
        result({ data }) {
          this.taxRateList = data?.TaxRates?.map((el) => new TaxRateModel(el)) ?? []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),
      copyFields() {
        return JSON.parse(JSON.stringify(this.fields))
      },
      getProduct() {
        return this.document?.paginationSpecifications?.specification?.find((el) => el.id === this.selected[0])?.product
      }
    },

    watch: {
      isAllSelected(newVal) {
        if (newVal) {
          this.selected = this.document.paginationSpecifications?.specification?.map((el) => el.id) ?? []
        } else this.selected = []
      }
    },

    mounted() {
      const srtFields = localStorage.getItem('outgoing_fields')
      this.fields = srtFields ? JSON.parse(srtFields) : this.default_fields
      document.addEventListener(
        'click',
        (e) => {
          this.listenerClick(e)
        },
        false
      )

      this.loadFields()
    },

    destroyed() {
      this.isUpdatedStorage = false
      document.removeEventListener('click', () => console.info('removeListener'))
    },

    methods: {
      showMarkingModal(data) {
        this.selectedProduct = data
        this.$bvModal.show('marking-modal')
      },
      update_data() {
        this.$emit('update-data')
      },
      edit_product() {
        this.$router.push({
          name: 'products.edit',
          params: { name: this.getProduct.name, id: this.getProduct.id }
        })
      },
      scrollUp() {
        this.$emit('scroll-up')
      },
      scrollDown() {
        this.$emit('scroll-down')
      },
      async loadFields() {
        const { data } = await this.$apollo.query({
          query: require('../../gql/ReadUserTable.graphql')
        })
        const table = data?.ReadUserTable?.tables?.find((el) => el.table_name === 'outgoing')
        if (table?.fields) this.fields = table.fields
      },
      centeredScroll({ offset }) {
        const clientWidth = document.querySelector('.table-docs').clientWidth
        document.querySelector('.table-docs').scrollTo({ left: offset - clientWidth / 2, behavior: 'smooth' })
      },
      mouseDown(e, field) {
        this.thElm = e.target.parentNode
        this.startOffset = this.thElm.offsetWidth - e.pageX
        this.field = field
      },
      setDefault() {
        this.fields = this.default_fields
        this.saveFields()
      },
      getRemainder(item) {
        const storage_id = this.document.storage.id

        if (Array.isArray(item)) {
          const num = item.filter((el) => el?.storage?.id === storage_id).reduce((sum, el) => sum + el.value, 0) || 0
          return num % 1 === 0 ? num : num?.toFixed(3)
        }

        return 0
      },

      setDelta(val, item) {
        item.setDelta(val)
        this.updateSpec(item)
      },

      setPrice(val, item) {
        item.setPrice(val)
        this.updateSpec(item)
      },

      setSum(val, item) {
        item.setSum(val)
        this.updateSpec(item)
      },

      setMarkup(val, item) {
        item.setMarkup(val)
        this.updateSpec(item)
      },

      setRetailPrice(val, item) {
        item.setRetailPrice(val)
        this.updateSpec(item)
      },

      setNds(val, item) {
        item.nds = val
        this.updateSpec(item)
      },

      formatPrice(summ = 0) {
        const val = summ === null ? 0 : summ

        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val)
      },

      searchProductResult(data) {
        const result = data.ProductSearch?.products ?? []

        if (result.length === 1) {
          this.search_result = false
          this.select_product(result[0])
        }

        return data
      },
      scroll_to(item) {
        const spec = this.document.paginationSpecifications.specification.find((el) => el.id === item.specification.id)
        if (item.found_by_barcode && item.brutto) {
          spec.setDelta(spec.delta + item.brutto)
          this.updateSpec(spec)
          return
        }
        setTimeout(() => {
          this.$refs[item.specification.id + 'delta'].showInput()
        }, 100)
        document.querySelector('.table-docs').scrollTo({
          top: this.$refs[item.specification.id + 'delta'].$el.offsetTop - 80
        })
      },
      select_product(item) {
        if (item.specification) {
          if (item.serial_status === true) {
            EventBus.$emit('notify', {
              unique: 'outgoing-spec',
              variant: 'primary',
              title: 'Марка добавлена',
              message: 'Марка добавлена в документ'
            })
          } else if (item.serial_status === false) {
            EventBus.$emit('notify', {
              unique: 'outgoing-spec',
              variant: 'danger',
              title: 'Марка удалена',
              message: 'Марка удалена из документа '
            })
          }

          if (!this.$refs[item.specification.id + 'delta']) {
            this.$emit('get_by_order', item.specification, () => this.scroll_to(item))
          } else {
            this.document.paginationSpecifications.specification
              .find((el) => el.id === item.specification.id)
              ?.setDelta(item.specification.delta)
            this.scroll_to(item)
          }

          return
        }
        const packageCount = item.package ? item.package[0]?.value : 0
        let delta = 0

        if (item.found_by_barcode && item.specification)
          delta = item.specification?.delta ? (item.specification?.delta || 0) + (packageCount || 1) : packageCount
        if (item.brutto && item.found_by_barcode) delta = item.brutto

        const new_spec = new OutgoingSpecificationModel({
          ...item.specification,
          // delta: 0,
          delta: item.specification?.delta ? (item.specification?.delta || 0) + (packageCount || 1) : packageCount,
          product: item,
          document_head: this.document,
          nds: item.taxRate,
          price: item?.purchasePrice || 0,
          retailPrice: item?.retailPrice || 0
        })

        new_spec.setDelta(delta)

        this.createSpec(new_spec.input, item.found_by_barcode)
      },
      async createSpec(spec, by_barcode) {
        const { data } = await this.$apollo.mutate({
          mutation: require(`../../gql/CreateOutgoingSpecification.gql`),
          variables: {
            productChange: [spec],
            document_head: this.document.id
          }
        })
        this.$emit('get-last', () => {
          setTimeout(() => {
            document.querySelector('.table-docs').scrollTo({
              top: document.querySelector('.table-docs').scrollHeight,
              left: 0
            })
          }, 500)
          this.$nextTick(() => {
            if (!by_barcode) {
              this.$refs[data.CreateOutgoingSpecification?.[0]?.id + 'delta']?.showInput()
            } else {
              setTimeout(() => document.getElementById('productSearchInput').focus(), 700)
            }
          })
        })
        this.$noty.show('Добавлено')
        this.removeProduct()
        document.querySelector('.table-docs').scrollTo({ left: 0, behavior: 'smooth' })
      },

      async updateSpec(spec) {
        await this.$apollo.mutate({
          mutation: require(`../../gql/CreateOutgoingSpecification.gql`),
          variables: {
            productChange: [{ ...spec.input, nds: spec.nds.id }],
            document_head: this.document.id
          }
        })
        this.$emit('update_table')
      },

      async removeSpecs() {
        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/RemoveOutgoingSpecification.gql'),
          variables: {
            document_head: this.document.id,
            ids: this.selected,
            isAllSelected: this.isAllSelected
          }
        })
        if (data?.RemoveOutgoingSpecification?.status) {
          this.isAllSelected = false
          this.selected = []
          this.$emit('update-data')
        }
      },

      removeProduct() {
        this.specToCreate = {}
        this.handler_product = false
      },

      setSupplierError(status = false) {
        this.isSupplierError = status
      },
      counterSeconds() {
        this.time = this.time + 0.1
      },

      resetTimer() {
        this.time = 0
      },

      clearSelected() {
        this.isAllSelected = false
        this.selected = []
      },
      openSortModal() {
        this.$bvModal.show('sort-table-modal')
      },
      // Action's
      back() {
        this.filter_params = this.filtersData()
        this.$nextTick(() => this.updateDocument())
      },
      listenerClick(e) {
        const target = e.target.classList.value

        if (
          target === 'cursor' ||
          target === 'span-count' ||
          target === 'span-count error' ||
          target === 'edit' ||
          target === 'ivu-select-placeholder' ||
          target === 'ivu-icon ivu-icon-ios-arrow-down ivu-select-arrow' ||
          target === 'form-control'
        )
          return true
      },

      async printPriceTags() {
        let products = []
        for (const prod of this.selected) {
          const found = this.document.paginationSpecifications.specification.find((el) => el.id === prod)
          if (found) {
            products.push(found.product.id)
          }
        }
        const { data } = await this.$apollo.query({
          query: require('../../gql/ReadPriceTagsFromDocument.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              document_head: this.document.id,
              branch: this.document.branch.id,
              product: products,
              isPrintAll: this.isAllSelected
            }
          }
        })
        this.printProducts = data?.ReadPriceTagsFromDocument?.map((el) => {
          return new ProductModel({ ...el?.Product, retailPrice: el?.value ?? el?.Product?.retailPrice })
        })
        if (this.printProducts?.length > 0) this.$bvModal.show('modal-print-tags')
      },
      changeFields(val) {
        this.fields = val
        this.saveFields()
      },

      showPriceUp() {
        this.$bvModal.show('modal-price-up')
      },

      async totalUpdateMarkup(priceup) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/AddMarkup.graphql'),
          variables: {
            input: {
              document_head: this.document.id,
              ids: this.selected,
              isAllSelected: this.isAllSelected,
              markup: +priceup.value,
              roundTo: priceup.priceup
            }
          }
        })
        if (data?.AddMarkup?.status) {
          this.$bvModal.hide('modal-price-up')
          this.isAllSelected = false
          this.selected = []
          this.$emit('update-data')
        }
      },
      async totalUpdateCosts(costsadd) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/AddCosts.graphql'),
          variables: {
            input: {
              document_head: this.document.id,
              ids: this.selected,
              isAllSelected: this.isAllSelected,
              costs: +costsadd.value,
              addParams: costsadd.addParams
            }
          }
        })
        if (data?.AddCosts?.status) {
          this.$bvModal.hide('modal-costs-add')
          this.isAllSelected = false
          this.selected = []
          this.$emit('update-data')
        }
      },
      search(value) {
        this.$emit('search', value)
      }
    }
  }
</script>
<style scoped lang="scss">
  ::v-deep() {
    .table-hover tbody tr:hover td,
    .table-hover tbody tr:hover th {
      background-color: white !important;
    }

    .table-hover tbody tr:hover td,
    .table-hover tbody tr:hover th {
      background-color: #f8f8f9 !important;
      transition: 1000ms ease;

      & .count {
        background-color: transparent;
        transition: none ease-in;
      }
    }

    .table-docs thead th {
      border-bottom: 1px solid #e1e1e1;
      border-right: 1px solid #e1e1e1;
      border-top: 1px solid #e1e1e1 !important;
    }

    .table-footer th {
      vertical-align: middle !important;
    }
  }

  .resize-field {
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    cursor: col-resize;
  }

  .field-column {
    position: relative;
  }

  .incoming-table {
    table-layout: fixed;
    width: auto;
  }

  table.fixed td {
    overflow: hidden;
  }

  .unselectable {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                   not supported by any browser */
  }

  .incoming-table {
    border: none !important;
  }

  .footer-data {
    color: #313131;
    margin-left: 32px;
  }

  .incoming-table-footer {
    border-top: 1px solid #e1e1e1 !important;
  }
</style>
