<template>
  <section id="overhead-document">
    <!-- <breadcrumbs :extra="outgoingDocument" /> -->
    <outgoing-head
      :document="outgoingDocument"
      :loading="loading"
      :storages_list="storages_list"
      @onChange="updateHead"
      @makeUsed="makeUsed"
    />
    <outgoing-spec-table
      :document="outgoingDocument"
      :busy="tableIsBusy"
      @update_table="refetchData"
      @scroll-down="scrollDown"
      @scroll-up="scrollUp"
      @get-last="getLast"
      @update-data="updateData"
      @search="search"
      @get_by_order="getByOrder"
    />
  </section>
</template>

<script>
  import { OutgoingHeadModel } from '@/views/operational-processes/models/outgoing-head.model'
  import OutgoingHead from '@/views/operational-processes/components/outgoing/OutgoingHead'
  import OutgoingSpecTable from '@/views/operational-processes/components/outgoing/OutgoingSpecTable'
  import { mapActions, mapGetters } from 'vuex'
  export default {
    name: 'Specifications',
    components: {
      OutgoingSpecTable,
      OutgoingHead
    },
    data() {
      return {
        loading: false,
        storages_list: [],
        changingStatus: false,
        outgoingDocument: new OutgoingHeadModel(),
        tableIsBusy: true,
        pagination: {
          skip: 0,
          take: 20,
          order: [{ key: 'order', value: 'ASC' }]
        },
        forwardDirection: false
      }
    },
    apollo: {
      OutgoingHead: {
        query: require('../../gql/getOutgoingHead.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            id: this.$route.query.id ? this.$route.query.id : this.$route.params.id,
            pagination: this.pagination
          }
        },
        result({ data }) {
          this.tableIsBusy = false
          const oldspec = this.outgoingDocument.id
            ? this.outgoingDocument.paginationSpecifications.specification || []
            : []
          this.outgoingDocument = new OutgoingHeadModel(data.OutgoingHead)
          if (!this.outgoingDocument?.entity?.id) {
            if (this.currentBranch?.entities?.length === 1) {
              this.outgoingDocument.setEntity(this.currentBranch?.entities[0])
            }
          }
          if (!this.outgoingDocument?.storage?.id) {
            if (this.storages_list.length === 1) {
              this.outgoingDocument.setStorage(this.storages_list[0])
            }
          }
          this.setBreadcrumbs({ ...data.OutgoingHead, is_go_back: true })
          if (this.outgoingDocument.id) {
            // const newDoc = new PaymentHeadModel(data.IncomingHead)
            if (!this.forwardDirection) {
              this.outgoingDocument.paginationSpecifications.specification.unshift(...oldspec)
            } else {
              const initialHeight = document.querySelector('.table-docs').scrollHeight
              this.outgoingDocument.paginationSpecifications.specification.push(...oldspec)
              if (oldspec.length > 0)
                this.$nextTick(() => {
                  document.querySelector('.table-docs').scrollTop =
                    document.querySelector('.table-docs').scrollHeight - initialHeight - 10
                })
            }
          }

          this.isUpdateFilters = false
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
          this.tableIsBusy = false
        }
      },

      Storages: {
        query: require('../../gql/getStorages.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.storages_list = data?.Storages || []
          if (!this.outgoingDocument.storage.id) {
            this.outgoingDocument.setStorage(data.Storages?.[0])
          }
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    },
    watch: {
      outgoingDocument() {
        this.$nextTick(() => {
          const table = document.querySelector('.table-docs')
          if (table.scrollHeight === table.clientHeight) {
            this.scrollDown()
          }
        })
      }
    },
    beforeMount() {
      this.setBreadcrumbs({ is_go_back: true })
    },
    beforeDestroy() {
      this.setBreadcrumbs({})
    },
    methods: {
      ...mapActions({
        setBreadcrumbs: 'breadcrumbs/set_current'
      }),
      updateHead() {
        if (this.outgoingDocument?.input?.id)
          this.$apollo.mutate({
            mutation: require('../../gql/UpdateOutgoingDocument.gql'),
            variables: this.outgoingDocument.input
          })
      },
      async refetchData() {
        const { data } = await this.$apollo.query({
          query: require('../../gql/getOutgoingHead.gql'),
          fetchPolicy: 'no-cache',
          variables: {
            id: this.$route.query.id ? this.$route.query.id : this.$route.params.id,
            pagination: {
              skip: this.outgoingDocument.paginationSpecifications.total,
              take: 1,
              order: [{ key: 'order', value: 'ASC' }]
            }
          }
        })
        const oldspec = this.outgoingDocument.paginationSpecifications.specification || []
        this.outgoingDocument = new OutgoingHeadModel(data.OutgoingHead)
        this.outgoingDocument.paginationSpecifications.specification.unshift(...oldspec)
      },
      updateData() {
        this.outgoingDocument.id = null
        this.pagination.skip = 0
        this.$apollo.queries.OutgoingHead.refetch()
      },
      async scrollDown() {
        const lastItem =
          this.outgoingDocument.paginationSpecifications.specification?.[
            this.outgoingDocument.paginationSpecifications.specification?.length - 1
          ]
        if (!lastItem) return
        const pagination = this.pagination
        this.forwardDirection = false
        pagination.skip = lastItem.order
        pagination.take = this.pagination.take
        if (pagination.skip >= this.outgoingDocument.paginationSpecifications.total) {
          return
        }
        this.pagination = pagination
        await this.$apollo.queries.OutgoingHead.refresh()
        this.pagination.take = 20
      },
      scrollUp() {
        if (this.pagination.skip <= 0 || !this.forwardDirection) return
        this.pagination.skip -=
          this.pagination.take + this.pagination.skip > this.outgoingDocument.paginationSpecifications.total
            ? this.outgoingDocument.paginationSpecifications.total - this.pagination.skip
            : this.pagination.take
        this.$apollo.queries.OutgoingHead.refresh()
      },
      async getLast(cb) {
        this.forwardDirection = true
        const pagination = Object.assign(this.pagination)
        pagination.skip = this.outgoingDocument.paginationSpecifications.total - pagination.take + 1
        if (pagination.skip < 0) pagination.skip = 0
        this.first = true
        await this.fetchHead(pagination)
        if (cb) cb()
      },
      async makeUsed(status) {
        if (this.changingStatus) return
        this.loading = true
        try {
          this.changingStatus = true
          await this.$apollo.mutate({
            mutation: require('../../gql/ChangeOutgoingStatus.gql'),
            variables: {
              id: this.outgoingDocument.id,
              fixed: status
            }
          })
          this.outgoingDocument.fixed = status
        } catch (e) {
          console.error(e)
          this.loading = false
        } finally {
          this.loading = false
          this.changingStatus = false
        }
        this.loading = false
      },
      async search(value) {
        this.pagination.search = value
        clearTimeout(this.timer)
        this.timer = setTimeout(() => this.updateData(), 500)
      },
      getHead(data) {
        this.tableIsBusy = false
        const oldspec = !this.first ? this.outgoingDocument.paginationSpecifications.specification || [] : []
        this.outgoingDocument = new OutgoingHeadModel(data.OutgoingHead)
        if (!this.first) {
          // const newDoc = new PaymentHeadModel(data.IncomingHead)
          if (!this.forwardDirection) {
            this.outgoingDocument.paginationSpecifications.specification.unshift(...oldspec)
          } else {
            const initialHeight = document.querySelector('.table-docs').scrollHeight
            this.outgoingDocument.paginationSpecifications.specification.push(...oldspec)
            if (oldspec.length > 0)
              this.$nextTick(() => {
                document.querySelector('.table-docs').scrollTop =
                  document.querySelector('.table-docs').scrollHeight - initialHeight - 10
              })
          }
        }

        this.isUpdateFilters = false
        this.first = false
      },
      async fetchHead(pagination) {
        const { data } = await this.$apollo.query({
          query: require('../../gql/getOutgoingHead.gql'),
          fetchPolicy: 'no-cache',
          variables: {
            id: this.$route.query.id ? this.$route.query.id : this.$route.params.id,
            pagination: pagination
          }
        })
        this.getHead(data)
      },
      async getByOrder(spec, cb) {
        const pagination = this.pagination
        pagination.skip = spec.order - 2
        if (pagination.skip > this.outgoingDocument.paginationSpecifications.total - pagination.take)
          pagination.skip = this.outgoingDocument.paginationSpecifications.total - pagination.take
        this.first = true
        await this.fetchHead(pagination)
        if (cb) cb()
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    #overhead-document {
      display: flex;
      flex-direction: column;
      height: 100%;
      flex: 1;
      min-height: 1px;
    }

    .b-action-buttons {
      button {
        min-width: 87px;
        justify-content: center;
      }
    }

    .table-b-table-default + .white-bg {
      background-color: white !important;
      border-bottom: 1px solid #e1e1e1 !important;
    }

    table {
      tr {
        th {
          border-bottom: 1px solid #e1e1e1;
          border-top: none;
        }
        td {
          border-bottom: 1px solid #e1e1e1 !important;
          border-left: none;
          border-right: none;
          border-bottom: 1px solid #e1e1e1 !important;
        }
      }
    }

    .b-table {
      &__wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        min-height: 1px;
      }

      &__content {
        flex-grow: 1;
        min-height: 1px;
        margin-top: 0;
        display: flex;
        flex-direction: column;
      }
    }

    .table-docs {
      margin-bottom: -1px;
      height: 100%;
      overflow-x: scroll;

      thead {
        position: sticky;
        top: -1px;
        background: #fff;
        z-index: 9;

        th {
          border-bottom: 1px solid #e1e1e1;
          border-right: 1px solid #e1e1e1;
          border-top: none;
        }
      }

      tr {
        th.b-checkbox {
          padding: 10px;

          .custom-checkbox {
            padding-left: 0;
            width: 16px;
            margin: 0 auto;

            .custom-control-label {
              &:before,
              &:after {
                left: -8px;
                box-shadow: none !important;
              }
            }
          }
        }
      }

      &.table-responsive {
        overflow-x: scroll;
      }

      &.b-table-sticky-header {
        max-height: none;
      }

      @media (max-height: 960px) {
        tr {
          td {
            &.td-name {
              padding: 5px 16px;

              .b-name.article {
                line-height: 17px;
                margin-top: 3px;
              }
            }
          }
        }
      }
    }

    .b-table__filter {
      .b-toggle-button {
        button {
          background: #e2e2e2;
          color: #313131;

          img {
            margin-right: 0;
            transition: all 0.2s;
            transform: rotate(180deg);
          }

          &.not-collapsed {
            img {
              transform: rotate(0);
            }
          }
        }
      }
    }

    .table-filter {
      button {
        background: #efefef;
        color: #313131;

        &:hover {
          background: #efefef;
          color: #313131;
        }
      }
    }

    .b-name {
      &.article {
        font-size: 12px;
        line-height: 24px;
        color: #888888;
        margin-top: 5px;
      }
    }

    .table-inner {
      max-width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      min-height: 1px;
      overflow-x: auto;

      .table-products {
        height: calc(100% - 58px);
        overflow-y: auto;
        width: 2000px;
        overflow-x: hidden;
      }

      table {
        width: 2000px;

        tr {
          td {
            padding: 10px 16px;
            vertical-align: middle;
            text-align: center;
          }
        }

        caption {
          padding: 0;

          td {
            border-top: none;
          }
        }
      }

      .b-table__navbar {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 60px;
      }
    }

    .b-search {
      &__wrapper {
        width: 100%;
        z-index: 10;

        & > div {
          position: fixed;
        }
      }

      &-result {
        position: absolute;
        bottom: 45px;
        left: 0px;
        width: 330px;
        background: #ffffff;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 0px 40px 7px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        padding: 10px 0;
        max-height: 460px;
        overflow-y: auto;
        z-index: 10000;

        .item {
          padding: 8px 14px;

          &-name {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #313131;
          }

          &-info {
            font-size: 12px;
            line-height: 24px;
            color: #888888;
            height: 24px;
            overflow: hidden;

            span {
              margin-right: 12px;
            }
          }

          &:hover {
            background: #efefef;
          }
        }
      }
    }

    .b-table__navbar {
      margin: -85px 15px 0;
    }

    .count {
      position: relative;
      display: inline-block;
      padding: 6px;

      .cursor {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin-left: 10px;
          opacity: 0;
        }
      }

      &:hover {
        img {
          opacity: 1;
        }
      }

      img {
        &.enter {
          position: absolute;
          top: 11px;
          right: 11px;
        }
      }
    }
  }

  #overhead-document {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    min-height: 1px;

    .table-docs {
      &.table-responsive {
        overflow-x: scroll;
      }

      &.b-table-sticky-header {
        max-height: none;
      }
    }

    .table-filter {
      button {
        &:hover {
          background: #efefef;
          color: #313131;
        }
      }
    }

    .b-name {
      &.article {
        font-size: 12px;
        line-height: 24px;
        color: #888888;
        margin-top: 5px;
      }
    }

    .b-search {
      &-result {
        .item {
          &:hover {
            background: #efefef;
          }
        }
      }
    }

    .count {
      img {
        &.enter {
          position: absolute;
          top: 11px;
          right: 11px;
        }
      }
    }

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 5px;
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
</style>
