var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "b-table__filter d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "b-toggle-button"
  }, [_c('b-button', {
    staticClass: "btn-filters cursor",
    on: {
      "click": _vm.changeVisibleParams
    }
  }, [_vm._v(" Параметры документа "), _c('img', {
    staticClass: "ml-3",
    class: {
      rotate: _vm.visibleParams
    },
    attrs: {
      "src": "/img/icons/arrow_up.svg",
      "alt": ""
    }
  })])], 1), _c('div', {
    staticClass: "b-action-buttons d-flex"
  }, [!_vm.document.fixed ? _c('e-button', {
    staticClass: "cursor",
    attrs: {
      "size": "m",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(" Сохранить черновик ")]) : _vm._e(), _c('e-button', {
    staticClass: "cursor ml-3",
    attrs: {
      "size": "m",
      "variant": "primary",
      "loading": _vm.loading,
      "disabled": _vm.loading
    },
    on: {
      "click": function ($event) {
        return _vm.updateDocument(_vm.document.fixed);
      }
    }
  }, [_vm.is_request_update ? [_c('b-spinner', {
    attrs: {
      "variant": "light",
      "small": ""
    }
  })] : [_vm._v(" " + _vm._s(_vm.document.fixed ? 'Редактировать' : 'Провести') + " ")]], 2)], 1)]), _c('e-collapse', {
    attrs: {
      "id": "collapse-filters",
      "show-button": false
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        var _vm$document;

        return [_c('filters-outcoming', {
          ref: "header_filters_outgoing",
          attrs: {
            "fixed": (_vm$document = _vm.document) === null || _vm$document === void 0 ? void 0 : _vm$document.fixed,
            "filter_params": _vm.document,
            "is_supplier_error": _vm.isSupplierError,
            "error_storage": _vm.errorStorage,
            "error_supplier": _vm.errorSupplier,
            "error_entity": _vm.errorEntity,
            "storages_list": _vm.storages_list
          },
          on: {
            "set_error_storage": function ($event) {
              _vm.errorStorage = null;
            },
            "set_error_entity": function ($event) {
              _vm.errorEntity = null;
            },
            "set_error_supplier": function ($event) {
              _vm.errorSupplier = null;
            },
            "change": _vm.change
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.visibleParams,
      callback: function ($$v) {
        _vm.visibleParams = $$v;
      },
      expression: "visibleParams"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }