<template>
  <b-modal
    id="marking-modal"
    centered
    size="lg"
    hide-footer
    header-class="m-0"
    @show="refetchMarking"
  >
    <template #modal-header>
      <h5>Список кодов маркировки | {{ spec?.product?.name || 'NONAME SSSSS' }}</h5>
      <b-button
        class="close"
        @click="hideModal"
      >
        <img
          src="/img/icons/modal_close.svg"
          alt=""
        />
      </b-button>
    </template>
    <span class="mr-3">Заявлено: {{ spec?.requestDelta || 0 }}</span>
    <span>Факт: {{ spec?.delta || 0 }}</span>
    <b-form-input
      v-model="pagination.search"
      class="mt-4 mb-3"
      style="width: 35%"
      placeholder="Поиск"
    />
    <div style="border-right: 1px solid #e1e1e1; border-left: 1px solid #e1e1e1">
      <b-table
        :fields="fields"
        :items="marks"
        show-empty
        empty-text="Нет маркировки"
      >
        <template #emptyfiltered>
          <span class="center"> Нет маркировки </span>
        </template>

        <template #head(fact)>
          <div style="display: flex; flex-direction: row; align-items: center">
            <b-checkbox
              switch
              disabled
            />
            <span>Статус кода маркировки</span>
          </div>
        </template>

        <template #cell(fact)="{ item }">
          <div style="display: flex; flex-direction: row; align-items: center">
            <b-checkbox
              v-model="item.fact"
              switch
              @change="(val) => setFact(val, item)"
            />
            <span
              style="
                padding: 5px 12px;

                font-size: 14px;
              "
              :style="{
                background: colors[item.fact ? 'accepted' : 'not_accepted'].background,
                color: colors[item.fact ? 'accepted' : 'not_accepted'].color
              }"
              class="ml-2"
              >{{ item.fact ? 'Принята' : 'Не принята' }}</span
            >
          </div>
        </template>

        <template #cell(serial_number)="{ item }">
          <div class="mark-code">
            <div class="serial_number">
              {{ item.serial?.serial_number ?? '-' }}
            </div>
            <div @click="copy(item.serial?.serial_number)">
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.93359 18H3.21875C1.66789 18 0.40625 16.7384 0.40625 15.1875V5.66016C0.40625 4.1093 1.66789 2.84766 3.21875 2.84766H9.93359C11.4845 2.84766 12.7461 4.1093 12.7461 5.66016V15.1875C12.7461 16.7384 11.4845 18 9.93359 18ZM3.21875 4.25391C2.44339 4.25391 1.8125 4.8848 1.8125 5.66016V15.1875C1.8125 15.9629 2.44339 16.5938 3.21875 16.5938H9.93359C10.709 16.5938 11.3398 15.9629 11.3398 15.1875V5.66016C11.3398 4.8848 10.709 4.25391 9.93359 4.25391H3.21875ZM15.5586 13.4297V2.8125C15.5586 1.26164 14.297 0 12.7461 0H4.94141C4.55304 0 4.23828 0.314758 4.23828 0.703125C4.23828 1.09149 4.55304 1.40625 4.94141 1.40625H12.7461C13.5215 1.40625 14.1523 2.03714 14.1523 2.8125V13.4297C14.1523 13.8181 14.4671 14.1328 14.8555 14.1328C15.2438 14.1328 15.5586 13.8181 15.5586 13.4297Z"
                  fill="#313131"
                />
              </svg>
            </div>
          </div>
        </template>
      </b-table>
    </div>
    <pagination
      v-if="type === 'outgoing'"
      :total="total"
      @change_pagination="handlerPagination"
    />
  </b-modal>
</template>

<script>
  import { IncomingSpecificationModel } from '@/models/incoming-spec.model'
  import Pagination from '@/views/shared/components/table/Pagination.vue'

  export default {
    name: 'MarkingModal',
    components: { Pagination },
    apollo: {
      ReadSerialsFromIncDoc: {
        query: require('../../gql/ReadSerialsFromIncDoc.graphql'),
        variables() {
          return {
            input: {
              document_head: this.document_head,
              product: this.spec?.product?.id
            }
          }
        },
        result({ data }) {
          this.marks = data?.ReadSerialsFromIncDoc ?? []
        },
        skip() {
          return !this.spec?.product?.id || this.type === 'outgoing'
        }
      },
      ReadSerialsFromOutDoc: {
        debounce: 300,
        fetchPolicy: 'no-cache',
        query: require('../../gql/ReadSerialsFromOutDoc.graphql'),
        variables() {
          return {
            input: {
              document_head: this.spec.id,
              product: this.spec?.product?.id,
              pagination: {
                skip: this.pagination.skip,
                take: this.pagination.take,
                search: this.pagination.search
              }
            }
          }
        },
        result({ data }) {
          this.marks = data?.ReadSerialsFromOutDoc?.serials ?? []
          this.total = data?.ReadSerialsFromOutDoc?.total || 0
        },
        skip() {
          return !this.spec?.product?.id || this.type !== 'outgoing'
        }
      }
    },
    props: {
      spec: {
        type: IncomingSpecificationModel,
        default: () => new IncomingSpecificationModel()
      },
      document_head: {
        type: String,
        default: () => ''
      },
      type: String
    },
    data() {
      return {
        fields: [
          { key: 'order', label: '№' },
          {
            key: 'fact',
            label: 'Статус кода маркировки',
            thStyle: 'width: 261px'
          },
          { key: 'serial_number', label: 'Код маркировки' }
        ],
        total: 0,
        pagination: { skip: 0, take: 20 },
        colors: {
          accepted: {
            background: '#e0edff',
            color: '#0066ff'
          },
          not_accepted: {
            background: '#EFEFEF',
            color: '#888888'
          },
          issued: {
            background: '#FFEBEE',
            color: '#E53835'
          },
          inflicted: {
            background: '#FFFAE7',
            color: '#D4A600'
          },
          circulation: {
            background: '#EBF9F5',
            color: '#00CB91'
          }
        },
        marks: [{ order: 1, status: 'accepted', marking: 1212312312 }]
      }
    },
    methods: {
      handlerPagination(pagination) {
        this.pagination = {
          take: pagination.take,
          skip: Number((pagination.current_page - 1) * pagination.take)
        }
      },
      async copy(info) {
        await navigator.clipboard.writeText(info)
        this.$noty.show(`Скопированно`)
      },
      hideModal() {
        this.$bvModal.hide('marking-modal')
      },
      refetchMarking() {
        this.$apollo.queries.ReadSerialsFromIncDoc.refetch()
      },
      async setFact(val, item) {
        if (this.type === 'outgoing') {
          await this.$apollo.mutate({
            mutation: require('../../gql/UpdateOutgoingSeria.graphql'),
            fetchPolicy: 'no-cache',
            variables: {
              input: {
                spec: this.spec.id,
                serial: item.serial.id
              }
            }
          })
        } else {
          await this.$apollo.mutate({
            mutation: require('../../gql/updateIncomingEdoSpecification.graphql'),
            fetchPolicy: 'no-cache',
            variables: {
              input: {
                document_head: this.document_head,
                serial: item.serial.serial_number,
                turn_on_serial: val
              }
            }
          })
        }
        this.$emit('update_fact')
      }
    }
  }
</script>

<style scoped lang="scss">
  .mark-code {
    display: flex;
    .serial_number {
      margin-right: 15px;
      max-width: 300px;
      white-space: nowrap;
      overflow: auto;
    }
    svg {
      cursor: pointer;
    }
  }
</style>
