import { render, staticRenderFns } from "./Specifications.vue?vue&type=template&id=801ef968&scoped=true&"
import script from "./Specifications.vue?vue&type=script&lang=js&"
export * from "./Specifications.vue?vue&type=script&lang=js&"
import style0 from "./Specifications.vue?vue&type=style&index=0&id=801ef968&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "801ef968",
  null
  
)

export default component.exports