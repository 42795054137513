var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "params-body"
  }, [_c('div', {
    staticClass: "card pb-0 pr-0 mb-0"
  }, [_c('b-form', {
    staticClass: "form"
  }, [_c('b-row', {
    staticClass: "w-100 align-items-end"
  }, [_c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Дата",
      "label-for": "work_period"
    }
  }, [_vm.fixed ? [_vm._v(" " + _vm._s(_vm.convertDate(_vm.filter_params.date)) + " ")] : [_c('date-picker', {
    staticClass: "elm-calendar",
    attrs: {
      "id": "work_period",
      "clearable": false,
      "placeholder": "Выберите дату",
      "value": _vm.filter_params.date,
      "format": "dd.MM.yyyy",
      "is-keyup": true,
      "transfer": ""
    },
    on: {
      "on-change": _vm.setDate
    }
  })]], 2)], 1), _c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    class: {
      error: _vm.error_supplier
    },
    attrs: {
      "label": "Поставщик или юрлицо",
      "label-for": "supplier"
    }
  }, [_vm.fixed ? [_vm._v(" " + _vm._s(_vm.filter_params.supplier.name) + " ")] : [_c('i-select', {
    attrs: {
      "value": _vm.filter_params.supplier.id,
      "filterable": "",
      "disabled": _vm.fixed,
      "is-keyup": true,
      "transfer": ""
    },
    on: {
      "on-change": _vm.setSupplier
    }
  }, _vm._l(_vm.supplier_list, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)]], 2)], 1), _c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Операция",
      "label-for": "operation"
    }
  }, [_c('i-select', {
    attrs: {
      "id": "operation",
      "value": _vm.filter_params.operation.name,
      "disabled": _vm.fixed,
      "transfer": ""
    },
    on: {
      "on-change": _vm.setOperation
    }
  }, _vm._l(_vm.operation_list, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1), _c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    class: {
      error: _vm.error_storage
    },
    attrs: {
      "label": "Место хранения",
      "label-for": "storage"
    }
  }, [_vm.fixed ? [_vm._v(" " + _vm._s(_vm.filter_params.storage.name) + " ")] : [_c('i-select', {
    attrs: {
      "id": "storage",
      "value": _vm.filter_params.storage.id,
      "transfer": ""
    },
    on: {
      "on-change": _vm.changeStorage
    }
  }, _vm._l(_vm.storages_list, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)]], 2)], 1), _c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    class: {
      error: this.error_entity
    },
    attrs: {
      "label": "Организация",
      "label-for": "entity"
    }
  }, [_vm.fixed ? [_vm._v(" " + _vm._s(_vm.filter_params.entity.name) + " ")] : _c('e-select', {
    attrs: {
      "selected_type": "text",
      "error": this.error_entity,
      "options": _vm.entity_list,
      "select_name": "entity_select"
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "entity"
        }, [_c('p', {
          staticClass: "name"
        }, [_vm._v(_vm._s(item.name))]), _c('div', {
          staticClass: "inn_kpp"
        }, [_c('p', {
          staticClass: "inn"
        }, [_vm._v("ИНН: " + _vm._s(item.inn ? item.inn : '-'))]), _c('p', {
          staticClass: "kpp"
        }, [_vm._v("КПП: " + _vm._s(item.kpp ? item.kpp : '-'))])])])];
      }
    }]),
    model: {
      value: _vm.active_entity,
      callback: function ($$v) {
        _vm.active_entity = $$v;
      },
      expression: "active_entity"
    }
  })], 2)], 1)], 1), _c('b-row', {
    staticClass: "w-100"
  }, [_c('b-col', [_c('div', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-extraparams",
      modifiers: {
        "collapse-extraparams": true
      }
    }],
    staticClass: "btn-extra-param cursor mb-3"
  }, [_c('span', {
    staticClass: "isHide"
  }, [_vm._v("Показать")]), _c('span', {
    staticClass: "isShow"
  }, [_vm._v("Скрыть")]), _vm._v(" дополнительные параметры ")]), _c('b-collapse', {
    staticClass: "row mb-3",
    attrs: {
      "id": "collapse-extraparams"
    }
  }, [_c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "lg": "6",
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Комментарий",
      "label-for": "comment"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "comment",
      "value": _vm.filter_params.comment,
      "rows": "3",
      "max-rows": "6",
      "is-keyup": true
    },
    on: {
      "input": _vm.setComment
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }